import React, {useEffect, useState} from "react";
import utils from "../math-utils";
import PlayAgain from "./PlayAgain";
import StarsDisplay from "./StarsDisplay";
import PlayNumber from "./PlayNumber";


const useGameState = () => {
    const [stars, setStars] = useState(utils.random(1, 9));
    const [availableNums, setAvailableNums] = useState(utils.range(1, 9));
    const [candidateNums, setCandidateNums] = useState([]);
    const [secondsLeft, setSecondsLeft] = useState(15);

    useEffect(() => {
        if (secondsLeft > 0 && availableNums.length > 0) {
            const timerId = setTimeout(() => {
                setSecondsLeft(secondsLeft - 1);
            }, 1000);
            return () => clearTimeout(timerId);
        }
    });

    const setGameState = (newCandidateNums) => {
        if (utils.sum(newCandidateNums) !== stars) {
            setCandidateNums(newCandidateNums);
        } else {
            const newAvailableNums = availableNums.filter(
                n => !newCandidateNums.includes(n)
            );
            setStars(utils.randomSumIn(newAvailableNums, 9));
            setAvailableNums(newAvailableNums);
            setCandidateNums([]);
        }
    }

    return {stars, availableNums, candidateNums, secondsLeft, setGameState};
};

const Game = (props) => {
        const {
            stars,
            availableNums,
            candidateNums,

            secondsLeft,
            setGameState,
        } = useGameState();

        const candidatesAreWrong = utils.sum(candidateNums) > stars;
        const gameStatus = availableNums.length === 0
            ? 'won'
            : secondsLeft === 0 ? 'lost' : 'active';


        const numberStatus = number => {
            if (!availableNums.includes(number)) {
                return 'used';
            }
            if (candidateNums.includes(number)) {
                return candidatesAreWrong ? 'wrong' : 'candidate';
            }
            return 'available';
        };

        const onNumberClick = (number, currentStatus) => {
            if (gameStatus !== 'active' || currentStatus === 'used') {
                return;
            }

            const newCandidateNums =
                currentStatus === 'available'
                    ? candidateNums.concat(number)
                    : candidateNums.filter(cn => cn !== number);
            setGameState(newCandidateNums);
        };
        return (
            <div className="game">
                <div className="help">
                    Kies 1 of meer cijfers die overeenkomen met de sterren
                </div>
                <div className="body">
                    <div className="left">
                        {gameStatus !== 'active' ? (
                            <PlayAgain onClick={props.startNewGame} gameStatus={gameStatus}/>
                        ) : (
                            <StarsDisplay count={stars}/>
                        )}
                    </div>
                    <div className="right">
                        {utils.range(1, 9).map(number =>
                            <PlayNumber key={number}
                                        status={numberStatus(number)}
                                        number={number}
                                        onClick={onNumberClick}/>
                        )}
                    </div>
                </div>
                <div className="timer">Resterende tijd: {secondsLeft}</div>
            </div>
        );
    }
;

export default Game;