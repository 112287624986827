import React from "react";

const PlayAgain = props => (
    <div className="game-done">
        <div
            className="message"
            style={{color: props.gameStatus === 'lost' ? 'red' : 'green'}}
        >
            {props.gameStatus === 'lost' ? 'Helaas' : 'Jouw code: 12'}
        </div>
        <button onClick={props.onClick}>Probeer opnieuw</button>
    </div>
)

export default PlayAgain;